<template>
  <div class="variety">
    <div class="variety-info-container">
      <div class="attribute">
        <div>
          <div class="type crop">
            <v-img width="14" :src="cropIcon"></v-img>
            {{ $t(currentCrop.messageKey).toUpperCase() }}
          </div>
        </div>
        <div class="data seed">
          {{ variety.cultivar }}
        </div>
      </div>
      <div class="attribute">
        <div class="type">
          <v-icon size="14" color="#AAB2A9">mdi-office-building-outline</v-icon>
          {{ $t('variety.owner') }}
        </div>
        <div class="data">
          {{ variety.titular }}
        </div>
      </div>
      <div class="attribute" v-if="variety.grupo">
        <div class="type">
          <v-icon size="14" color="#AAB2A9">mdi-poll</v-icon>
          {{ $t('cultivar.gmr') }}
        </div>
        <div class="data">
          {{ variety.grupo }}
        </div>
      </div>
      <div class="attribute" v-if="variety.habito && !isUSA">
        <div class="type">
          {{ $t('cultivar.habito_crescimento') }}
        </div>
        <div class="data">
          {{ $t('cultivar.growth_habit.' + variety.habito.toLowerCase()) }}
        </div>
      </div>
      <div class="attribute" v-if="variety.ciclo_dias">
        <div class="type">
          {{ $t('cultivar.ciclo') }}
        </div>
        <div class="data">
          {{ cicleStart }} {{ $t('until') }} {{ cicleEnd }} {{ $t('days') }}
        </div>
      </div>
      <div
        class="attribute"
        v-if="variety.populacao_min && variety.populacao_max && !isUSA"
      >
        <div class="type">
          {{ $t('cultivar.populacao') }}
        </div>
        <div class="data">
          {{
            $t('cultivar.populacao_entre', [
              variety.populacao_min,
              variety.populacao_max,
            ])
          }}
        </div>
      </div>
      <div
        class="attribute"
        v-if="variety.semeadura_ini && variety.semeadura_fim && !isUSA"
      >
        <div class="type">
          {{ $t('cultivar.epoca_semeadura') }}
        </div>
        <div class="data">
          {{ $t('_to_', [plantingTimeStart, plantingTimeEnd]) }}
        </div>
      </div>
      <div class="attribute" v-if="variety.ogm && !isUSA">
        <div class="type">OGM</div>
        <div class="data">
          {{ variety.ogm }}
        </div>
      </div>
    </div>
    <div class="variety-info-actions">
      <v-btn
        data-id="disease-control"
        v-if="isRegisteredVariety"
        class="planning-btn text-none"
        :to="{
          path: planningRoutePath,
          query: {
            fazenda_id: currentFarmId,
            cultivar_id: variety.cultivar_id,
          },
        }"
        >{{ $t('cultivar.go_to_planning') }}
      </v-btn>
      <v-btn
        v-else
        data-id="add-variety-button"
        class="planning-btn text-none"
        :disabled="areaExceededAccount"
        @click="addVariety"
      >
        <v-progress-circular v-if="loading" size="18" indeterminate />
        <div v-else class="planning-icon">
          {{ $t('cultivar.create_variety') }}
          <v-icon size="18" class="ml-3">mdi-plus</v-icon>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { diseaseProgram } from '@/modules/menu/menuItems.js'

export default {
  name: 'VarietyInfo',

  props: {
    variety: { type: Object, required: true },
  },

  data() {
    return {
      loading: false,
      planningRoutePath: diseaseProgram.path,
    }
  },

  computed: {
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('user', ['areaExceededAccount', 'isUSA']),
    isRegisteredVariety() {
      return this.variety?.added
    },
    cropIcon() {
      return require(`/public/img/${this.currentCrop.name}.png`)
    },
    plantingTimeStart() {
      return this.variety.semeadura_ini
        ? this.variety.semeadura_ini.formatDate(this.$t('date_without_year'))
        : null
    },
    plantingTimeEnd() {
      return this.variety.semeadura_fim
        ? this.variety.semeadura_fim.formatDate(this.$t('date_without_year'))
        : null
    },
    cicleStart() {
      return (
        parseInt(this.variety.ciclo_dias) -
        parseInt(process.env.VUE_APP_CICLO_PERIODO_TOLERANCIA)
      )
    },
    cicleEnd() {
      return (
        parseInt(this.variety.ciclo_dias) +
        parseInt(process.env.VUE_APP_CICLO_PERIODO_TOLERANCIA)
      )
    },
  },

  methods: {
    ...mapActions('cultivares', ['createVariety']),
    async addVariety() {
      try {
        this.loading = true
        const { data } = await this.createVariety({
          seedId: this.variety.semente_id,
          farmId: this.currentFarmId,
        })
        const cultivarId = data.cultivar_id

        this.$root.$emit(
          'notify',
          'success',
          this.$t('cultivar.salvar.sucesso')
        )

        this.$router.push({
          path: this.planningRoutePath,
          query: {
            fazenda_id: this.currentFarmId,
            cultivar_id: cultivarId,
          },
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.variety-info-actions {
  display: flex;
  margin-top: 20px;
}
.planning-btn {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
  color: #fff;

  .planning-icon {
    display: flex;
    align-items: center;
  }
}

.attribute {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
}

.variety-info-container {
  display: grid;
  grid-template-rows: 50px 50px max-content;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px;
}

.type {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  color: #aab2a9;
  display: flex;
  gap: 4px;
  text-transform: uppercase;
  text-align: center;
}
.type.crop {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.data {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #5b6459;
  text-align: center;
}

.data.seed {
  color: #1a2b46;
  font-size: 18px;
}

@media (max-width: 960px) {
  .type,
  .data {
    font-size: 10px;
  }
  .data.seed {
    font-size: 14px;
  }
}
</style>
