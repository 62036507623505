<template>
  <div class="df-flex-sm df-flex-col variety-list">
    <variety-item
      v-for="(variety, index) in varietiesRender"
      :key="index"
      class="df-col-12"
      :variety="variety"
      :selected-variety="selectedVariety"
      @removeVariety="removeVariety"
      @addVariety="addVariety"
      @selectVariety="selectVariety"
    />
    <div v-if="varieties.length == 0" class="no-result">
      <h1>{{ $t('variety.no_result_title') }}</h1>
      <p>{{ $t('variety.no_result') }}</p>
    </div>
    <request-not-cataloged-variety />
  </div>
</template>

<script>
import VarietyItem from '@/pages/cultivares/VarietyItem.vue'
import RequestNotCatalogedVariety from '@/pages/cultivares/RequestNotCatalogedVariety.vue'

export default {
  name: 'VarietyList',

  components: {
    VarietyItem,
    RequestNotCatalogedVariety,
  },

  props: {
    varieties: { type: Array, required: true },
    selectedVariety: { type: Number, required: true },
  },

  data() {
    return {
      renderAmount: 20,
      varietiesRender: [],
    }
  },

  mounted() {
    this.checkScroll()
  },

  watch: {
    varieties() {
      this.renderVarieties()
    },
  },

  methods: {
    checkScroll() {
      const container = document.querySelector('.variety-list')

      container.onscroll = () => {
        const scrollY = container.scrollHeight - container.scrollTop
        const height = container.offsetHeight
        const offset = scrollY - height
        if (offset < 100 && this.renderAmount < this.varieties.length) {
          this.renderAmount += 10
          this.renderVarieties()
        }
      }
    },
    renderVarieties() {
      this.varietiesRender = this.varieties.slice(0, this.renderAmount)
    },
    removeVariety(value) {
      this.$emit('removeVariety', value)
    },
    addVariety(varietyObject) {
      this.$emit('addVariety', varietyObject)
    },
    selectVariety(value) {
      this.$emit('selectVariety', value)
    },
  },
}
</script>

<style scoped>
.variety-list {
  overflow-y: auto;
}
.no-result {
  width: 350px;
  margin: 0 auto;
}
.no-result h1 {
  text-align: center;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 18px;
  color: #1a2b46;
}
.no-result p {
  text-align: center;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  color: #51594f;
}
@media (max-width: 960px) {
  .no-result {
    width: 100%;
  }
}
</style>
