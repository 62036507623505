<script>
export default {
  props: {
    crop: { type: String, required: true },
  },
}
</script>

<template>
  <v-card>
    <div class="content">
      <div>
        <v-card-subtitle>
          {{ $t('variety.unavailable_create') }}
        </v-card-subtitle>
        <v-card-title>
          {{ $t('variety.unavailable_wheat_region_title', [crop]) }}
        </v-card-title>
        <v-card-text>
          {{ $t('variety.unavailable_wheat_region_complement', [crop]) }}
        </v-card-text>
      </div>
      <div>
        <v-img src="img/farmer-sowing.webp" width="170px" />
      </div>
    </div>
  </v-card>
</template>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 700px;
  margin: 0 auto;
  padding: 80px 0px;
}

.v-image {
  margin-left: 100px;
}

.v-card {
  border-radius: 8px;
  box-shadow: 0px 2px 9px rgba(230, 233, 230, 0.3);
}

.v-card__subtitle {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  color: #51594f;
  padding: 0px;
}

.v-card__title {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 38px;
  line-height: 40px;
  color: #1a2b46;
  padding: 0px;
  word-break: break-word;
  margin: 6px 0px;
}

.v-card__text {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  color: #51594f;
  line-height: 20px;
  padding: 0px;
  word-break: break-word;
}
@media (max-width: 960px) {
  .content {
    flex-direction: column-reverse;
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .v-card__subtitle,
  .v-card__title,
  .v-card__text,
  .v-card__actions {
    margin: 10px 40px;
    width: auto;
  }

  .v-image {
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .v-card {
    height: 100%;
  }
}
</style>
