<template>
  <df-page-header
    :title="$t('menu.cultivares')"
    :subtitle="$t('menu.planejamento')"
  />
</template>

<script>
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'

export default {
  name: 'VarietyHeader',

  components: {
    DfPageHeader,
  },
}
</script>
