<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      variety: '',
      loading: false,
    }
  },

  methods: {
    ...mapActions('cultivares', ['modalSolicitacao']),

    async submit() {
      this.loading = true
      const response = await this.modalSolicitacao({
        cultivar: this.variety,
        msg: 'Formulário Cultivares',
      })

      if (response) {
        this.$root.$emit(
          'notify',
          'success',
          this.$t('cultivar.solicitacao.sucesso')
        )
      } else {
        this.$root.$emit(
          'notify',
          'error',
          this.$t('cultivar.solicitacao.erro')
        )
      }
      this.close()
    },

    close() {
      this.loading = false
      this.dialog = false
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog">
    <template #activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" class="notCataloged">
        <p>{{ $t('variety.request_not_catologed_variety') }}</p>
        <v-icon size="18" color="#39AF49">mdi-send</v-icon>
      </button>
    </template>
    <v-card>
      <v-card-title>
        <p>{{ $t('variety.request_variety') }}</p>
        <button @click="close">
          <v-icon size="18" color="#AAB2A9">mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="variety"
          :loading="loading"
          :label="$t('variety.not_cataloged_variety')"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <button class="close" @click="close">
          <u>{{ $t('cancelar') }}</u>
        </button>
        <button class="submit" @click="submit">
          <p>{{ $t('enviar') }}</p>
          <v-icon size="18" color="#FFFFFF">mdi-check-bold</v-icon>
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
::v-deep .v-dialog {
  max-width: 40%;
  background-color: #ffffff;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.notCataloged {
  padding: 4px 12px;
  background-color: #ffffff;
  color: #3f4e3c;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin: 18px auto;
  width: 280px;
  height: 32px;
}

.submit {
  padding: 4px 12px;
  width: 85px;
  height: 32px;
  background-color: #39af49;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.close {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: #5b6459;
}

.v-dialog {
  border-radius: 8px;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.05);
}

.notCataloged p,
.submit p {
  display: contents;
}

.v-card__title {
  font-family: 'Rubik';
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1a2b46;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.v-card__text {
  background-color: #f8f9f8;
}

.v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

@media (max-width: 960px) {
  ::v-deep .v-dialog {
    max-width: 100%;
  }
}
</style>
