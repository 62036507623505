<script>
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  props: {
    ownerList: { type: Array, required: true },
    reset: { type: Boolean, required: true },
  },

  data() {
    return {
      panel: [0, 1],
      dialog: false,
      owner: [],
      GMR: [0, 10],
    }
  },

  mounted() {
    logEvent(events.varieties.clickedFunction_Order)
  },

  methods: {
    apply() {
      logEvent(events.varieties.clickedFunction_Filter)
      this.$emit('filterUpdate', this.GMR, this.owner)
      this.dialog = false
    },
    close() {
      this.dialog = false
    },
  },

  computed: {
    ...mapGetters('crops', ['isSoybean']),
  },

  watch: {
    reset(value) {
      if (value === true) {
        this.owner = []
        this.GMR = [0, 10]
        this.$emit('filterUpdate', this.GMR, this.owner)
      }
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" class="open">
        <p>{{ $t('filter') }}</p>
        <v-icon size="18" color="#39AF49">mdi-filter</v-icon>
      </button>
    </template>
    <v-card>
      <v-card-title>
        <p>{{ $t('filter') }}</p>
        <button @click="close">
          <v-icon size="18" color="#AAB2A9">mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels accordion flat multiple v-model="panel">
          <v-expansion-panel v-if="isSoybean">
            <v-expansion-panel-header>GMR</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-range-slider
                v-model="GMR"
                step="1"
                track-color="#C3D1E9"
                track-fill-color="#1A2B46"
                thumb-color="#1A2B46"
                thumb-label="always"
                min="0"
                max="10"
              ></v-range-slider>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="owner">
            <v-expansion-panel-header>{{
              $t('variety.owner')
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="company in ownerList"
                :key="company"
                class="ownerList"
              >
                <input type="checkbox" :value="company" v-model="owner" />
                {{ company }}
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <button class="close" @click="close">
          <u>{{ $t('cancelar') }}</u>
        </button>
        <button class="apply" @click="apply">
          <p>{{ $t('filter') }}</p>
          <v-icon size="18" color="#FFFFFF">mdi-check-bold</v-icon>
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.open {
  background-color: #fff;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  padding: 4px 12px;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  color: #3f4e3c;
  width: 130px;
}

.apply {
  padding: 4px 12px;
  width: 85px;
  height: 32px;
  background-color: #39af49;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.close {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: #5b6459;
}
.open p,
.apply p {
  display: contents;
}
::v-deep .v-dialog {
  border-radius: 8px;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.05);
}

.v-card__title {
  font-family: 'Rubik';
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1a2b46;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.v-card__text {
  background-color: #f8f9f8;
  padding: 0px 10px !important;
}

.v-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.ownerList {
  background-color: #e6e9e6;
  border-radius: 8px;
  min-height: 40px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #0e180c;
  padding: 12px;
  margin: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ownerList input {
  appearance: none;
  background: #f8f9f8;
  border: 1px solid #aab2a9;
  border-radius: 4px;
  margin-right: 4px;
  min-width: 16px;
  min-height: 16px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.ownerList input:checked {
  border: 5px solid #39af49;
}

.v-expansion-panel.owner {
  overflow-y: scroll;
  max-height: CALC(100vh - 370px);
}

.v-expansion-panel-content {
  padding: 0px 50px;
}

.v-expansion-panels,
.v-expansion-panel,
.v-expansion-panels button {
  background-color: #f8f9f8 !important;
}

::v-deep .v-slider--horizontal .v-slider__track-container {
  height: 10px;
}
::v-deep .v-slider--horizontal .v-slider__track-fill {
  background-color: #1a2b46 !important;
}

::v-deep .v-slider__track-background {
  border-radius: 8px;
}

::v-deep .v-slider__thumb-label {
  background-color: #1a2b46;
  border-radius: 30px !important;
  position: relative;
  top: 5px;
  height: 25px !important;
  width: 25px !important;
}

::v-deep .v-slider__thumb-label span {
  position: relative;
  bottom: 20px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #1a2b46;
}

.v-expansion-panels button {
  box-shadow: none;
  border: none;
  width: 100%;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #1a2b46;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 10px 0px 0px 0px !important;
}

@media (max-width: 960px) {
  .open {
    width: 40px;
  }
  .open p {
    display: none;
  }
}
</style>
