<template>
  <v-dialog v-model="dialog" :fullscreen="isMobile">
    <template #activator="{ on, attrs }">
      <v-list-item>
        <v-list-item-title v-bind="attrs" v-on="on">
          <button class="open-dialog" :disabled="!variety">
            <v-icon color="#39AF49" size="24">mdi-information-symbol</v-icon>
          </button>
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        <p>
          {{ $t('variety.variety_info') }}
        </p>
        <button @click="close">
          <v-icon size="18" color="#AAB2A9">mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text>
        <variety-info :variety="variety" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin.vue'
import VarietyInfo from '@/pages/cultivares/VarietyInfo.vue'

export default {
  name: 'VarietyInfoModal',

  mixins: [LayoutMixin],

  components: {
    VarietyInfo,
  },

  props: {
    variety: { default: null, type: [() => null, Object] },
  },

  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    close() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
::v-deep .v-dialog {
  max-width: fit-content;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.v-card {
  background-color: #f8f9f8;
}

.v-card__text {
  background-color: #ffffff;
  box-shadow: 0px 2px 9px rgba(230, 233, 230, 0.3);
  border-radius: 8px;
  margin: 0px 20px;
  padding: 10px !important;
  width: fit-content;
}

.v-card__title {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-family: 'Rubik';
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1a2b46;
}

.open-dialog {
  background: #ffffff;
  border: 1px solid #e6e9e6;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  border-radius: 4px;
  padding: 4px;
}

@media (max-width: 960px) {
  ::v-deep .v-dialog {
    max-width: 100%;
    margin: 0px;
  }

  .v-card__text {
    margin: 10px 20px;
    width: auto;
  }
}
</style>
